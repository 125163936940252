/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    @include before-desktop {
        flex-grow: 1;
        opacity: 0;
        pointer-events: none;
    }

    @include desktop {
        text-align: right;
    }

    &_isVisible {
        opacity: 1;
        pointer-events: all;
    }

    &_isActive {
        --search-bar-width: 27vw;
        --search-field-border-color: var(--primary-light-color);
    }

    &-Wrapper {
        @include desktop {
            margin-right: 1.2rem;
        }
    }

    &-Input {
        transition-property: opacity, background-color;
        transition-duration: 200ms;
        background-color: var(--search-field-background);
        border-color: transparent;
        border-radius: 0;
        border-width: 1px;
        font-size: 1.6rem;
        max-width: 0;
        min-width: 0;
        opacity: 1;
        width: 100%;
        padding: {
            bottom: 8px;
            left: 10px;
            top: 8px;
        };

        @include before-desktop {
            max-width: 100%;
        }

        @include desktop {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid var(--search-input-desktop-border-color);
            border-radius: 0;
            font-size: 1.4rem;
            margin: 4.8rem 3.6rem 0 0;
            max-width: 100%;
            padding-left: 3.6rem;
            width: calc(100% - 7.2rem);
            padding-bottom: 7px;
        }

        &:focus,
        &:active {
            --input-color: var(--search-field-color);

            @include desktop {
                border: 0;
                border-bottom: 1px solid var(--search-input-desktop-border-color);
            }
        }
    }

    &-Placeholder {
        font-size: 1.6rem;
        height: min-content;
        left: calc(50% - 5px);
        padding-left: 20px;
        pointer-events: none;
        position: absolute;
        top: 8px;
        transform: translate3d(-50%, 0, 0);
        visibility: hidden;
        width: min-content;

        @include search-icon;
        @include mobile {
            left: 50%; 
            width: max-content;
        } 
        &::before {
            bottom: 0;
            left: 0;
            margin: auto;
            top: 0;
            transform: translateY(-2px);
        }

        &_isActive {
            left: 0;
            opacity: .7;
            padding-left: 0;
            transform: translate3d(10px, 0, 0);

            &::before {
                opacity: 0;
            }
        }

        &_isPlaceholderVisible {
            visibility: visible;
        }
    }

    &-SearchIcon,
    &-CloseIcon {
        cursor: pointer;

        @include desktop {
            height: 24px;
            width: 16px;
        }
    }

    &-SearchIcon {
        @include search-icon;

        &::before {
            box-shadow:
                6px 6px 0 -7px var(--header-color),
                7px 7px 0 -7px var(--header-color),
                8px 8px 0 -7px var(--header-color),
                9px 9px 0 -7px var(--header-color),
                10px 10px 0 -7px var(--header-color),
                11px 11px 0 -7px var(--header-color);
            height: calc(16px - var(--header-icon-stroke-width) * 2);
            left: 0;
            top: 2px;
            width: calc(16px - var(--header-icon-stroke-width) * 2);
        }
    }

    &-CloseIcon {
        @include close-button;

        &::before,
        &::after {
            height: 22px;
            left: 8px;
            top: 2px;
            width: 2px;
        }
    }

    &-SearchWrapper {
        background: var(--search-field-visible-background);
        height: 100%;
        max-width: 360px;
        position: fixed;
        right: 0;
        top: calc(var(--header-total-height) + 3px);
        transform: translateX(100%);
        transition: transform 300ms ease-in;
        width: 100%;
        will-change: transform;
        border-left: 1px solid var(--primary-divider-color);

        &_isVisible {
            transform: translateX(0);
        }
    }

    &-SearchInnerWrapper {
        .SearchField-SearchIcon {
            left: 4.2rem;
            position: absolute;
            top: 4.8rem;

            @include mobile {
                left: 4.9rem;
                top: 5.6rem;
            }
        }
    }
}

.SrcField1 {display: none;}
.SrcBtn1 {display: none;}

.aa-Autocomplete {
        padding:30px 0 0 0;

    .SearchOverlay-Results {
        padding: 0 3.6rem;
    }
    .aa-InputWrapper {
        margin: 0px auto 12px;
        padding: 0;
        position: relative;
        width: calc(100% - 60px);

        &:before {
            content: "";
            position: absolute;
            width: calc(10px - var(--header-icon-stroke-width)*2);
            height: calc(10px - var(--header-icon-stroke-width)*2);
            border: var(--header-icon-stroke-width) solid var(--header-color);
            border-radius: 50%;
            box-shadow: 4px 4px 0 -4px var(--header-color),5px 5px 0 -4px var(--header-color),6px 6px 0 -4px var(--header-color),7px 7px 0 -4px var(--header-color);
            box-shadow: 6px 6px 0 -7px var(--header-color),7px 7px 0 -7px var(--header-color),8px 8px 0 -7px var(--header-color),9px 9px 0 -7px var(--header-color),10px 10px 0 -7px var(--header-color),11px 11px 0 -7px var(--header-color);
            height: calc(16px - var(--header-icon-stroke-width)*2);
            left: 0;
            top: 12px;
            width: calc(16px - var(--header-icon-stroke-width)*2);
            z-index:2;
        }

    }
    .aa-Input {
        border:0; 
        border-bottom: 1px solid #000;
        width: 100%;
        padding: 13px 20px 13px 32px;
    }

}

.NewSrch .aa-Autocomplete {
    padding: 0;
}
.NewSrch .aa-Autocomplete .aa-InputWrapper {
    margin: 5px auto 0px;
    padding: 0;
    position: relative;
    width: 100%;
}
.NewSrch .aa-Autocomplete .SearchOverlay-Results {
    position: absolute;
    background: #fff;
    width: 100%;
    border: 1px solid #ccc;
    border-top: 0;
    padding: 0 15px 20px;
    max-height: 75vh;
    overflow: auto;
}



:root {
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding: .6rem 0;
    margin-bottom: 0;

    @include mobile {
        padding: .7rem 0;
    }

    @include desktop {
        overflow: hidden;
    }

    &::before {
        content: none;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 1.2rem;
        right: 1.2rem;
        height: 1px;
        background-color: var(--search-item-divider-color);

        @include mobile {
            left: 1.4rem;
            right: 1.4rem;
        }
    }

    &:last-child {
        margin-bottom: 0;

        &::after {
            content: none;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 4.8rem calc(100% - 6rem);
        grid-column-gap: 1.2rem;

        @include mobile {
            grid-template-columns: 5.6rem calc(100% - 7rem);
            grid-column-gap: 1.4rem;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-CustomAttribute {
        text-transform: uppercase;
        font-weight: 200;
        font-size: .96rem;
        line-height: 1.44rem;

        @include mobile {
            font-size: 1.12rem;
            line-height: 1.68rem;
        }
    }

    &-Title {
        color: var(--search-item-title-color);
        font-size: 1.32rem;
        font-weight: 300;
        max-width: calc(100vw - 8.4rem);

        @include mobile {
            font-size: 1.54rem;
            max-width: calc(100vw - 9.8rem);
        }

        &_isLoaded {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &-Link {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }
}

.srcBarH12 {
    font-weight: 700;
    text-align: left;
    padding: 10px 0 10px;
    margin: 0px 0 0 0;
    border-top: 0px solid #ccc;
    font-size: 18px;
}
.srcBarH1 {
    font-weight: 700;
    text-align: left;
    padding: 20px 0 10px;
    margin: 20px 0 0 0;
    border-top: 1px solid #ccc;
    font-size: 18px;
}
.guidslink, .guidslink ul, .guidslink li, .guidslink h4 {text-align: left;}

 #seeallseach {
    margin: 0 auto;
    background: #c41b48;
    border-color: #d72a58;
 }
 