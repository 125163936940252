
.home-services{
    --link-color: var(--primary-base-color);
    --link-hover: #000;
    @include mobile{
        display: none;
    }
    li::before{
        display: none;
    }
    .service-items{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        grid-gap: 10px;
        -webkit-grid-gap: 10px;
    }
    .service-item{
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin: 0;
        @include mobile{
            padding-left: 0;
        }
    }
    .service-link{
        border: 0;
        text-decoration: none;
        padding-left: 30px;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 14px;
        padding-bottom: 0;
        color: var(--link-color);
        &:hover{
            border: none;
            color: var(--link-hover);
        }
    }
}
.icons{
    .service-link{
        background: url(../../images/sprite.png) no-repeat;
    }
    .money-back{
        background-position:-394px -126px;
    }
    .return{
        background-position: -532px -186px;
    }
    .email{
        background-position: -65px -274px;
    }
    .delivery{
        background-position: -227px -325px;
    }
    .guarantee{
        background-position: -402px -259px;
    }
    .video{
        background-position: -534px -314px;
    }
}
.home-bottom-services{
    .home-services{
        margin: 20px 0;
    }
}



