.text-content-wrapper{
    display: flex;
    gap: 30px;
    margin-bottom: 60px;
    @include mobile{
        flex-wrap: wrap;
        gap: 0;
        margin-bottom: 40px;
    }
    .col-md-6{
        width: 50%;
        @include mobile{
            width: 100%;
        }
    }
    a{
        color: var(--link-color);
        text-decoration: none;
        &:hover{
            color: #000;
        }
    }
    h2{
        text-transform: none;
        font-weight: 500;
        margin-bottom: 10px;
    }
}
.front-text {
    color: #5a5a5a;
    .front-title{
        font-size: 36px;
        margin-top: 20px;
        margin-bottom: 40px;
        font-weight: 400;
        display: inline-block;
        color: #5a5a5a;
        @include mobile{
            font-size: 30px;
            margin-top: 0px;
            margin-bottom: 20px;
        }
    }
}
.paint-gallery-sect{
    margin-bottom: 60px;
    @include mobile{
        margin-bottom: 40px;
    }
}