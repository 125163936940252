/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CmsBlock {
    &-Wrapper_type {
        &_header_cms {
            order: 3;
        }
    }
}

.artikelguide_page {
    .video-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        margin: 0 0 20px 0;
    }

    .video-row h2 {
        width: 100%;
        padding: 0 5px;
        margin: 0 0 10px 0;
    }

    .vid-guide-thumb {
        width: 33.3333%;
        padding: 10px 5px 10px;
    }

    .vid-guide-thumb a {
        display: block;
    }

    .atgtxt1 {
        padding: 0 0px 20px;
    }

    .atgtxt1 p {
        font-size: 16px;
    }

    .vid-guide-thumb p {
        margin: 10px 0 0 0;
    }

    .vid-guide-thumb p a {
        color: #08b3ff;
    }

    .atgimg .Image_ratio_square {
        padding-bottom: 0;
        height: auto;
        overflow: unset;
        display: block;
    }
    .atgimg img.Image-Image {
        position: relative;
    }
}


.need-help-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px dotted #d72a58;
    padding: 10px;
    margin: 0 0 15px 0;
    

    .need-help-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
    }

    .need-help-img div.Image-Image {
        position: relative;
        height: auto;
        overflow: visible;
        top: auto;
        left: auto;
    }

    .need-help-img .Image_ratio_square.img-responsive .Image-Image {
        position: relative;
        top: auto;
        left: auto;
        display: block;
    }

    .need-help-img .Image-Image:after {
        display: none;
    }

    .need-help-content {
        flex: 1;
        padding: 0 0 0 10px;
    }
}