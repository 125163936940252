/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --header-background: #fff;
    --header-color: #000;
    --header-contacts-color: #414141;
    --header-icon-stroke-width: 2px;
    --header-search-clear-background: #838383;
    --header-desktop-button-color: var(--primary-base-color);
	--header-banner-close-button-background: #000;
    --header-height: 100px;
    --header-nav-height: 50px;
    --demo-notice-height: 0px;
    --header-total-height:
        calc(
            var(--header-height)
            + var(--offline-notice-height)
            + var(--demo-notice-height)
            + env(safe-area-inset-top)
        );

    @include desktop {
        --header-dots-color: #000;
    }

    @include after-mobile {
        --header-nav-height: 60px;
        --header-top-menu-height: 30px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height));
    }
}

@supports not (height: env(safe-area-inset-top)) {
    :root {
        --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height));
    }
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    padding: 7.5px;
    width: 35px;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    --header-logo-width: 0;
    --header-logo-height: 0;

    position: fixed;
    top: var(--demo-notice-height);
    left: 0;
    width: 100%;
    z-index: 96;
    background-color: var(--header-background);
    padding-top: env(safe-area-inset-top, 0);

    @include desktop {
        border-bottom: 1px solid var(--primary-divider-color);
    }

    @at-root .hiddenHeader {
        @include before-desktop {
            /* stylelint-disable-next-line length-zero-no-unit */
            --header-height: 0px;
        }
    }

    &-Wrapper {
        @include desktop {
           // margin-bottom:calc(var(--header-total-height) + var(--demo-notice-height));
			margin-bottom: calc(151px + var(--demo-notice-height));
        }
    }

    &_isHiddenOnMobile {
        @include before-desktop {
            pointer-events: none;
            opacity: 0;
        }
    }

    &_name {
        &_cart {
            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--primary-base-color);
                }
            }
        }

        &_cart_overlay {
            .Header-Button_type_minicart,
            .Header-MinicartTitle {
                @include after-mobile {
                    --header-color: var(--primary-base-color);
                }
            }
            .Header-MinicartItemCount {
                @include after-mobile {
                    color: var(--primary-base-color);
                }
            }
        }

        &_menu,
        &_menu_subcategory {
            @include mobile {
                background-color: var(--secondary-base-color)
            }
            .Header-Button_type {
                &_close {
                    @include desktop {
                        @include button-visible;
                    }
                }

                &_menu {
                    @include desktop {
                        @include button-invisible;
                    }
                }
            }
        }

        &_customer_account,
        &_customer_sub_account {
            .Header-Button_type_account,
            .Header-MyAccountTitle {
                @include desktop {
                    --header-color: var(--header-desktop-button-color);
                }
            }
        }

        &_checkout {
            .Header-Button,
            .Header-SearchWrapper,
            .SearchField {
                @include desktop {
                    display: none;
                }
            }
            
        }
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
                margin-top: 5px;
            }

            &-Nav {
                @include desktop {
                    justify-content: flex-end;
                }
                @include mobile {
                    > * .Header-Title{
                        text-transform: uppercase;
                    }
                    .Header-MyAccountTitle{
                        display: none;
                    }
                } 
            }

            &-MyAccount {
                @include before-desktop {
                    position: absolute;
                    right: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include before-desktop {
                top: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Banner {
        background: var(--primary-base-color);
        color: $white;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: block;
        z-index: 100;

        strong {
            margin-left: 3px;
        }
    }

    &-TopBanner {
		background: var(--header-banner-background);
		@include before-desktop {
			display: none;
		}
        &_isTopBannerHidden{
            display: none;
        }
        &Wrapper {
            display: flex;
            margin: auto;
            padding: 0 1.2rem;
            justify-content: space-between;
            max-width: var(--content-wrapper-width);
			height: var(--header-banner-height);
			max-height: var(--header-banner-height);
            .CmsBlock-Wrapper_type_TopBannerCms {
                display: flex;
                flex-grow: 1;
            }
        }
        &Close {
            display: none;
            justify-content: center;
            align-items: center;
            padding-left: 1rem;
			padding-right: var(--header-banner-close-padding-right);
			background: var(--header-banner-close-background);
            div + & {
                display: flex;
            }
        }
        &CloseBtn {
            cursor: pointer;
			width: 20px;
			height: 20px;
			position: relative;
			&::before, &::after {
				content: '';
				display: block;
				height: 20px;
				width: 2px;
				background: var(--header-banner-close-button-background);
				position: absolute;
				top: 50%;
				left: 50%;
				pointer-events: none;
			}
			&::before {
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&::after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
        }
    }

    &-TopMenu,
    &-Nav {
        display: flex;
        margin: auto;
        max-width: var(--content-wrapper-width);
        @include before-desktop {
            flex-wrap: wrap;
        }
    }

    &-TopMenu {
        height: var(--header-top-menu-height);
        justify-content: space-between;
        z-index: 21;
        padding: {
            left: 1.2rem;
            right: 2.4rem;
            top: 1.2rem;
        };
        margin-bottom: 20px;

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 2.8rem;
                top: 1.4rem;
            }
        }
    }

    &-Contacts {
        font-size: 1.2rem;
        color: var(--header-contacts-color);

        span:first-child {
            margin-right: 20px;
        }
    }

    &-CallTimes{
        width: 100%;
        text-align: center;
        background-color: #F1E9E0;
        height: var(--demo-notice-height);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 96;
        p{
            color: black;
            margin-bottom: 0;
            span{
                font-weight: 600;
            }
        }
    }

    &-Nav {
        align-items: center;
        height: var(--header-nav-height);
        padding: 0 1.2rem;
        z-index: 20;

        @include mobile {
            padding: 0.5rem 1.4rem;
            & > * {
                display: flex;
                align-items: center;
                height: 50px;
                width: 100%;
                .Header-Title {
                    font-size: 2.1rem;
                }
            }
            & > *:nth-child(3) {
                height: 35px;
            }
        }

        @include desktop {
            justify-content: flex-end;
        }
    }

    &-Button {
        @include button-invisible;

        cursor: pointer;
        margin: 0;
        height: 35px;

        &_isVisible {
            @include before-desktop {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_account,
            &_minicart {
                @include desktop {
                    @include button-visible;
                }
            }

            &_close {
                @include close-button;
            }

            &_back {
                @include back-button;
            }

            &_menu {
                @include menu-button;

                @include desktop {
                    width: 100px;
                }
            }

            &_account {
                @include account-button;

                &.Header-Button_isVisible {
                    overflow: hidden;
                }
            }

            &_minicart {
                @include before-desktop {
                    @include minicart-button;
                }

                @include desktop {
                    cursor: default;
                    height: auto;
                    margin-right: 1.44rem;
                    width: auto;
                }
            }

            &_searchClear {
                @include clear-search-button;
            }

            &_clear {
                @include clear-button;
            }

            &_edit {
                text-transform: uppercase;

                &.Header-Button_isVisible {
                    width: 70px;
                    opacity: .5;
                }
            }

            &_share {
                @include share-button;
                display: none;
                &.Header-Button_isVisible {
                    display: flex;
                    height: 0;
                    width: 0;
                    margin-left: 1rem;
                    margin-top: .5rem;
                    opacity: .5;
                }
            }

            &_home {
                @include home-icon;
            }

            &_ok,
            &_cancel {
                font-size: 1.4rem;
                opacity: .5;

                &.Header-Button_isVisible {
                    width: 70px;
                }
            }

            &_compare {
                @include button-visible;

                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-CompareButtonWrapper {
        border-right: 2px dotted var(--header-dots-color);
        margin: 0 20px 0 7px;
        padding-right: 20px;
    }

    &-CompareIcon {
        @include compare-icon(var(--header-color));
    }

    &-MyAccount {
        display: none;
        @include mobile {
            height: 50px;
            // margin-left: auto;
        }
        &_isVisible {
            display: flex;
        }
        &Wrapper {
            align-items: center;
            display: flex;
            @include mobile {
                --header-color: #929292;
                flex-direction: column;
            }
        }

        &Title {
            cursor: pointer;
            padding-right: .48rem;
            text-transform: uppercase;
            color: var(--header-color);
            //margin: auto;

            @include mobile {
                order: 1;
                padding-right: 0;
                font-size: 1rem;
            }
        }
    }

    &-MobileMenuButton {
        display: none;
        flex-direction: column;
        align-items: flex-end;
        order: -1;
        margin-right: auto;
        @include mobile {
            --header-color: #929292;
            height: 50px;
        }
        &Title {
            color: var(--header-color);
            text-transform: uppercase;
            font-size: 1rem;
        }
        &_isVisible {
            display: flex;
        }
    }

    &-Minicart {
        &ButtonWrapper {
            align-items: center;
            border-right: 2px dotted var(--header-dots-color);
            cursor: pointer;
            display: flex;
            height: 26px;
            margin-left: 2.4rem;
            padding-right: 2.16rem;

            @include mobile {
                --header-color: #929292;
                display: none;
                flex-direction: column;
                height: 50px;
                margin-left: .8rem;
                padding-right: 0;
            }

            @include tablet-portrait {
                margin-left: 0;
            }
            &_isVisible {
                display: flex;
            }
        }

        &Title {
            color: var(--header-color);
            margin-right: .84rem;
            text-transform: uppercase;

            @include mobile {
                margin-right: 0;
                font-size: 1rem;
            }
        }

        &Icon {
            @include minicart-button;

            @include desktop {
                height: 20px;
                width: 20px;
                top: 1px;
            }
        }

        &ItemCount {
            line-height: 13px;
            min-width: 14px;
            padding-left: 5px;
            @include before-desktop {
                background: var(--primary-base-color);
                border-radius: 7px;
                color: var(--header-background);
                display: inline-block;
                font-size: 1.2rem;
                left: 50%;
                padding: 3px 4px 0px;
                position: absolute;
                top: 17px;
                z-index: 2;
            }
        }
    }

    &-LogoWrapper {
        position: absolute;
        height: var(--header-logo-height);
        width: var(--header-logo-width);
        max-width: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        opacity: 0;
        overflow: hidden;

        transition-property: opacity;
        transition-duration: 200ms;

        // @include desktop {
        //     @include logo-visible;
        // }

        @include mobile {
            // position: unset;
        }

        &_isVisible {
            @include logo-visible;
        }

        .Image-Image {
           /* object-fit: fill; */
        }
    }

    &-Title {
        opacity: 0;
        max-width: 0;
        transition-property: opacity;
        transition-duration: 200ms;

        text-align: center;
        font-size: 1.56rem;
        color: var(--header-color);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        pointer-events: none;

        position: absolute;
        margin: auto;
        width: 100%;
        height: 1.8rem;
        line-height: 1.8rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include mobile {
            font-size: 1.82rem;
            height: 2.1rem;
            line-height: 2.1rem;
            right: -15px;
            text-align: left;
        }

        &_isVisible {
            @include before-desktop {
                opacity: 1;
                max-width: calc(100% - 80px);
            }
        }
    }

    a {
        &:hover,
        &:focus {
            @include before-desktop {
                text-decoration: none;
            }
        }
    }
    &-Nav > .SearchField {
        @include mobile {
            display: none;
            flex-grow: 0;
            flex-basis: 100%;
            order: 4;
            &.SearchField_isVisible {
                display: inline;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .CurrencySwitcher { display:none; }
    .StoreSwitcher { display:none; }
}

.ProductCard {
    .CoverageRange-Box {
        .CoverageRange-Range { 
            .Arrow {
                left: 2px;
            }
        }
    }
}


.contacts-wrapper dd {margin-right: 0;}
.contacts-wrapper dt {
    margin-left: 8px;
    margin-right: 2px;
}

main p a {text-decoration: underline !important;}

li.Breadcrumb>div {
    display: inline-block;
}

// This is for adjusting the banner height of a cms banner block.
// Just define the height in a <style> tag with the following CSS in the cms block:
// <style>:root { --header-banner-height: 50px; }</style>
#root {
	@include desktop {
		padding-top: var(--header-banner-height);
		&.Header-Root_isTopBannerHidden {
			padding-top: 0;
		}
	}
}

// bloxk 1
.set1 {display: inline-block; width: 100%;}
.set1img { float: left; margin: 0 15px 15px 0; max-width: 450px;}
.set1 h2 { font-size: 20px; margin: 0 0 12px 0;}
.set1 p { margin: 0 0 8px 0; font-size: 14px; line-height: 20px;}
.set1img img { max-width: 100%;}
.set1img .Image { display: block; height: auto; width: auto; padding: 0;}
.set1img img.Image-Image {position: relative;}
@media screen and (max-width:979px) {
.set1img { max-width: 50%; }
}
@media screen and (max-width:639px) {
.set1img { margin:0 0 12px 0;  max-width:100%; }
}

// block 2
.set2 h2 { font-size: 20px; margin: 0 0 12px 0;}
.set2 p { margin: 0 0 8px 0; font-size: 14px; line-height: 20px;}
ul.set2Link { display: flex; align-items: flex-start; justify-content: space-between; flex-direction: row; flex-wrap: wrap;font-size: 0;margin:15px 0 0 0;}
ul.set2Link li {width: calc((100% - 45px) / 4);margin: 0 0 15px 0;padding: 0;}
ul.set2Link a {overflow: hidden;display: block;font-size: 0;}
.set2 img { max-width: 100%; transition: all ease-in-out 0.3s;}
.set2 .Image { display: block; height: auto; width: auto; padding: 0;}
.set2 img.Image-Image {position: relative;}
ul.set2Link li:before {display: none;}
ul.set2Link a:hover img { transform: scale(1.05);}
@media screen and (max-width:767px) {
ul.set2Link li {width: calc((100% - 15px) / 2);}
}

// block 3
.set3 h1 { font-size: 20px; margin: 0 0 12px 0;}
.set3 p { margin: 0 0 8px 0; font-size: 14px; line-height: 20px;}
.set3 img { max-width: 100%; transition: all ease-in-out 0.3s;}
.set3 .Image { display: block; height: auto; width: auto; padding: 0;}
.set3 img.Image-Image {position: relative;}
ul.set3Box .set3Liin {display: flex; justify-content: space-between;}
.set3Txt h2 {font-size: 18px;margin: 0 0 6px 0;}
ul.set3Box li {margin: 0 0 20px 0; padding: 0;}
ul.set3Box li:before {display: none;}
.set3Img {max-width: 12%;width: 100%;}
.s3LinkBx a {display: inline-block; text-decoration: unset; font-size: 12px; text-transform: uppercase; font-weight: bold; padding: 14px 20px; background: #606378; color: #fff;}
.s3LinkBx a:hover { background: #515368; }
.s3LinkBx {margin: 20px 00 0 0;}
.set3Txt {flex: 1;width: 100%; box-sizing: border-box;}
ul.set3Box li:nth-child(odd) .set3Txt {padding:0 0px 0 15px;}
ul.set3Box li:nth-child(even) .set3Txt {padding:0 15px 0 0;}
ul.set3Box li:nth-child(odd) .set3Liin {flex-direction: row;}
ul.set3Box li:nth-child(even) .set3Liin {flex-direction: row-reverse;}
.CmsPage-Content ul.set3Box {margin: 20px 0 0 0;}
@media screen and (max-width:767px) {
ul.set3Box li:nth-child(odd) .set3Liin, ul.set3Box li:nth-child(even) .set3Liin {flex-direction:column;}
ul.set3Box li:nth-child(odd) .set3Txt, ul.set3Box li:nth-child(even) .set3Txt {padding:15px 0 0 0;}
.set3Img {max-width: 100%; text-align: center;}
.s3LinkBx { text-align: center; }
}

// block 4
.set4 h1 { font-size: 20px; margin: 0 0 10px 0;}
.set4 p { margin: 0 0 8px 0; font-size: 14px; line-height: 20px;}
.set4 img { max-width: 100%; transition: all ease-in-out 0.3s;}
.set4 .Image { display: block; height: auto; width: auto; padding: 0;}
.set4 img.Image-Image {position: relative;}
.s4ImgRow {display: flex;flex-direction: row;justify-content: space-between;align-items: flex-start;}
.s4ImgClm {width: calc((100% - 40px) / 3);}
.s4Set1 {margin: 20px 0 0 0;}
.s41Row {display: flex;flex-direction: row;justify-content: space-between;align-items: flex-start;}
.s41Clm {width: calc((100% - 20px) / 2);}
ul.s4Set2 {margin: 20px 0 0 0;}
ul.s4Set2 li {margin: 0 0 20px 0; padding:0;}
ul.s4Set2 li:before {display: none;}
ul.s4Set2 .set4Liin {display: flex;justify-content: space-between;}
ul.s4Set2 li:nth-child(odd) .set4Txt {padding:0 0px 0 15px;}
ul.s4Set2 li:nth-child(even) .set4Txt {padding:0 15px 0 0;}
ul.s4Set2 li:nth-child(odd) .set4Liin {flex-direction: row;}
ul.s4Set2 li:nth-child(even) .set4Liin {flex-direction: row-reverse;}
.set4Img {max-width: 40%;width: 100%;}
.set4Txt {flex: 1;width: 100%; box-sizing: border-box;}
@media screen and (max-width:767px) {
ul.s4Set2 li:nth-child(odd) .set4Liin, ul.s4Set2 li:nth-child(even) .set4Liin {flex-direction:column;}
ul.s4Set2 li:nth-child(odd) .set4Txt, ul.s4Set2 li:nth-child(even) .set4Txt {padding:15px 0 0 0;}
.set4Img {max-width: 100%; text-align: center;}
.s4LinkBx { text-align: center; }
.s4ImgClm { width: calc((100% - 20px) / 3);}
.s41Row {flex-direction:column;}
.s41Clm {width: 100%;}
}