/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-content-height: 320px;
    --footer-copyright-height: 50px;
}

.Footer {
    
    .pwaversion {
    text-align:center;
    color:darkgrey;
    background-color:#303030;
    }
    
    $column-count: 4;

    @include mobile {
        margin-bottom: var(--navigation-tabs-height);
    }

    &-CopyrightContentWrapper {
        background-color: #292929; 
        color: #e6e6e6;
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);

        @include after-mobile {
            padding: 15px 0;
        }
    }

    &-Copyright {
        font-size: 1.5rem;
        text-align: center;

        a {
            color: inherit;
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color:#303030;
    }

    &-Columns {
        display: flex;
        height: 100%;
        padding: 35px 0px;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            align-items: center;
        }

        @include after-mobile {
            padding: 35px 0px;
        }

        @include tablet {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
    }

    &-Column {
        &:not(:last-of-type) {
            @include mobile {
                margin-bottom: 30px;
            }

            @include after-mobile {
                padding-right: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }
    }

    &-ColumnTitle {
        text-transform: uppercase;
        margin: 0 0 15px;
        font-size: 2rem;

        @include after-mobile {
            margin: 0 0 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }

        a + a {
            margin-left: 0;
        }
    }

    &-ColumnItem {
        color: inherit;
        margin-bottom: 12px;
        font-size: 1.6rem;

        &:last-of-type {
            margin: 0;
        }

        &_type_image {
            width: 25px;

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-left: 100px;
            }
        }
    }
}




.footerBx2 {
    color: #e6e6e6;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    a {
        color: #e6e6e6;
        text-decoration: none;

        &:hover {
            color: #fff;
        }
    }

    p {
        font-size: 13px;
        color: #e6e6e6;
    }

    .ftrcol {
        width: 25%;
        padding: 0 12px;

        .ftrtitle {
            color: #fff;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.footerBx3 {
    display: none;
    text-align: center;

    .scrollTop {
        background: #07b3ff;
        width: 168px;
        color: #fff;
        height: 45px;
        line-height: 45px;
        cursor: pointer;
        font-weight: 600;
        margin: 0 auto;

        &:hover {
            background:#0da7ea;
        }
    }
}


@media screen and (max-width:767px) { /* 640 px */

    .footerBx2 {
        flex-direction: column;
        text-align: center;
        width: 100%;

        .ftrtitle {
            font-weight: bold;
        }

        .ftrcol {
            width: 100%;
            margin: 0 0 30px 0;
        }
        .ftrcol.ftrcol4 {
            margin: 0 0 0px 0;
        }
    }
    
}