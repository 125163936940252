:root {
    --green-button-background:#00b592;
    --green-button-border: #00b592;
    --green-button-color: #{$white};
    --green-button-hover-background: #00b592;
    --green-button-hover-color: #333;
    --green-button-hover-border: #00b592;
}
.service-card-wrapper{
    grid-gap: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: #5a5a5a;
    @include mobile{
        grid-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 20px;
    }
    .card{
        margin: 20px auto;
        width: 100%;
        height: auto;
        min-height: 380px;
        background: white;
        padding: 15px;
        border-radius: 0px;
        border: solid 1px #eee;
        position: relative;
        z-index: 90;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        @include mobile{
            margin: 0;
        }
    }
    h5 {
        font-size: 20px;
        font-weight: bold;
    }
    a{
        text-decoration: none;
        color: var(--link-color);
        &:hover{
            color: #000;
            text-decoration: none;
        }
    }
    .btn-wrapper{
        margin-top: 28px;
        a {
            color: #fff;
            &:focus,
            &:hover{
                color: #333;
            }
        }
        .btn-green{
            @include mobile{
                display: block;
            }
        }
    }
    
    .btn{
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        color: #fff;
        &:hover,
        &:focus{
            color: #333;
            text-decoration: none;
        }
    }
    .btn-green{
        color: var(--green-button-color);
        background-color: var(--green-button-background);
        border-color:var(--green-button-border);
        border-radius: 0px;
        &:hover,
        &:focus{
            text-decoration: none;
            color: var(--green-button-hover-color);
            background-color: var(--green-button-hover-background);
        }
    }
}

.ribbon-wrapper-green1 {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -2px;
    right: -2px;
}
.ribbon-green1 {
    font-weight: bold;
    font-family: Sans-Serif;
    color: #b5b5b5;
    font-size: 15px;
    text-align: center;
    text-shadow: white 0px 1px 0px;
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate( 45deg);
    position: relative;
    padding: 7px 0;
    left: -2px;
    top: 8px;
    width: 120px;
    background: #07b3ff;
    color: #FFFFFF;
    &:before,
    &:after {
        content: "";
        border-top: 3px solid #6e8900;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        position: absolute;
        bottom: -3px;
    }
    &:before {
        left: 0px;
    }
    &:after {
        right: 0px;
    }
}



.farvehandel-aarhus {
    position: absolute;
    left: 167px;
    top: 156px;
}
