
.gallery-items{
    grid-gap:30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 20px;
    @include mobile{
        grid-gap:15px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 15px;
    }
}

.gallery-items.col-4-img{
    grid-gap:30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include mobile{
        grid-gap: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
.content-col{
    text-align: center;
    margin-top: 25px;
}
.gallery-heading{
    margin: 0 0 20px 0;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    line-height: 26px;
    color: #5a5a5a;
    @include mobile{
        margin: 15px 0 20px 0;
        font-size: 20px !important;
    }
}
.bbh-link{
    color: var(--link-color);
    text-transform: uppercase;
    padding-bottom: 2px;
    border-bottom: 1px solid var(--link-color);
    text-decoration: none;
    font-weight: 400;
    &:hover{
        border-bottom: 2px solid var(--pink-button-background);;
        text-decoration: none;
    }
}
.review-section{
    margin-top: 80px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    @include mobile{
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .middle-heading{
        margin-bottom: 15px;
    }
}
.solution-section{
    margin-bottom: 60px;
    @include mobile{
        margin-bottom: 40px;
    }
}
@media only screen and (max-width: 575px) {
    .paint-gallery-sect{
        .gallery-items.col-4-img {
            grid-template-columns: repeat(2, 1fr);
        }    
    }
    .gallery-items.col-4-img {
        grid-template-columns: repeat(1, 1fr);
    }
    .gallery-items{
        grid-gap:15px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .content-col {
        text-align: center;
        padding-bottom: 25px;
        margin-bottom: 0;
    }
}