:root {
    --pink-button-background:#d72a58;
    --pink-button-border: #d72a58;
    --pink-button-hover-background: #E36A8A;
    --pink-button-hover-color: #{$white};
    --pink-button-hover-border: #E36A8A;
}
.mt-20{
    margin-top: 20px;
}
.mt-40{
    margin-top: 40px;
}
.mb-40{
    margin-bottom: 40px;
}
.mt-60{
    margin-top: 60px;
}
.mt-90{
    margin-top: 90px;
}
.mb-70{
    margin-bottom: 70px;
}
.mt-0{
    margin-top: 0 !important;
}
.text-center{
    text-align: center!important;
}
.w-100{
    width: 100%  !important;
}
.hidden-xs{
    @include mobile{
        display: none;
    }
}
.img-responsive{
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
}
.Image_ratio_square.img-responsive{
    padding-bottom: 0;
    height: auto;
    max-width: 100%;
    .Image-Image{
        object-position: unset;
        object-fit: unset;
        position: static;
        width: auto !important;
        height: auto !important;
        max-width: 100%;
    }
}
.home-top-banner{
    position: relative;
    margin-bottom: 50px;
    @include mobile{
        margin-bottom: 30px;
    }
    .content-col{
        display: none;
        @include mobile{
            display: block;
        }
    }
    .banner-btn{
        padding: 11px 20px 12px;
        background: var(--pink-button-background);
        position: absolute;
        left: 50%;
        bottom: 110px;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        border: 0;
        &:hover{
            background: var(--pink-button-hover-background);
            border: 0;
        }
        @include mobile{
            bottom: 50px;
        }
    }
}

.middle-heading{
    text-align: center;
    font-size: 22px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
    color: #5a5a5a;
}
