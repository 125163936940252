
.create-acc-wrapper{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center ;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 40px;
    @include mobile{
        flex-wrap: wrap;
    }

}
.create-acc {
    .content-left-col{
        padding: 0 15px;
         display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center ;
        flex-flow: column;
        flex: 0 0 50%;
        text-align: center;
        @include mobile{
            flex: 0 0 100%;
            margin-bottom: 20px;
            margin-top: 30px;
        }
        .pre-title{
            font-size: 17px;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 0px;
        }
        .post-title {
            font-size: 14px;
            margin: 0 0 1em;
        }
        .acc-link{
            font-weight: bold;
            font-size: 17px;
            border-width: 2px;
        }
    }
    .image-right-col {
        flex: 0 0 50%;
        @include mobile{
            flex: 0 0 100%;
            text-align: center;
        }
    }
    h2 {
        margin: 5px 0 15px;
        text-transform: uppercase;
        font-size: 30px !important;
        font-weight: 700;
        letter-spacing: 2px;
        color: #5a5a5a;
    }
}

